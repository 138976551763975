import Vue from "vue";
import {NidVerification, CardToIban} from "@/libs/Finnotech";

let that = Vue.prototype

that.$sleep = (ms) => (new Promise(resolve => setTimeout(resolve, ms)))

that.$p2e = string => string.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))

that.$toLocal = (number) => {
    //purify number
    number = number + ''
    if (number.includes('E')) {
        number = parseFloat(number) + ''
    }
    number = that.$p2e(number).replace(/[^\d.]|\.\./ig, '').replace(/,/g, '')
    if (number.includes('.')) {
        number = number.split('.')[0] + '.' + (number.split('.')[1] || '')
    }

    if (parseFloat(number)) {

        if (parseFloat(number) < 1.0e-8) {
            //zero numbers
            return 0
        } else {
            //separate number
            const int = parseFloat(number.split('.')[0]).toLocaleString()
            if (number.includes('.')) {
                return int + '.' + (number.split('.')[1] || '')
            } else {
                return int
            }
        }

    } else {
        return number
    }

}

that.$S2N = e => parseFloat(e.replace(/,/g, ''))

that.$marketType2relatedCoin = marketType => marketType.replace(/_TOMAN|_TETHER/, '')

that.$error = async (title, text, icon = 'error', button = 'متوجه شدم') => {
    await that.$swal({
        icon: icon,
        title: title,
        text: text,
        confirmButtonText: button,
        customClass: {
            confirmButton: 'btn-success',
        },
    })
}

that.$count = (find, array) => {
    let count = 0;
    for (let i = 0; i < array.length; i++)
        if (array[i].includes(find))
            count++;
    return count;
}

that.$prettifyPrice = (number) => {
    let price = Number.parseFloat(number);
    if (price >= 1) {
        return price.toFixed(2);
    }
    return parseFloat(price.toPrecision(4));
}

that.$toSnakeCase = str =>
    str &&
    str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toUpperCase())
        .join('_');

that.$numFormatter = num =>
    num < 1000 ? num.toLocaleString()
        : num < 1000000 ? `${(num / 1000).toFixed(1).toLocaleString()}k`
        : num < 1000000000 ? `${(num / 1000000).toFixed(1).toLocaleString()}m`
            : `${(num / 1000000000).toFixed(1).toLocaleString()}b`;

that.$getUserInfo = async () => {
    let info = await that.$axios('admins/init')
    info = info.data.data;
    let role = info.roles[0] ?? []

    let abilities = [];
    if (role.abilities) {
        abilities = role.abilities.map((i) => {
            return i.name
        });
    } else {
        abilities = []
    }
    that.state.userInfo = {
        firstName: info.name,
        lastName: info.lastname,
        role: role.name,
        rolePersian: role.label,
        privileges: abilities,
    }

    if (that.state.userInfo.privileges.includes('LIST_WITHDRAW')) {
        // const requestCount = await that.$axios('/wallets/withdrawal-requests?withdrawalRequestStatus=RAISED_BY_USER')
        // that.state.requestCount = requestCount.data.totalElements
    }


    that.state.gotten = true
}

that.$getExchangeStatus = async  () => {
    let info = await that.$axios.get('/configs/MAIN/AUTO_TRADE')
    info = info.data.data;
    that.state.exchangeIsAuto = info.value === 'AUTO'
}

that.$makeBookmarkData = async (e) => {
    let data = []
    const loop = async (e, title = '', icon = '') => {
        await e.forEach(el => {
            if (!el.children) {
                if (!title) {
                    data.push(
                        {
                            title: el.title,
                            route: {name: el.route},
                            icon: el.icon,
                            isBookmarked: localStorage.bookmarks ? JSON.parse(localStorage.bookmarks).includes(el.title) : false,
                        }
                    )
                } else {
                    data.push(
                        {
                            route: {name: el.route},
                            title: title + "/ " + el.title,
                            icon: icon,
                            isBookmarked: localStorage.bookmarks ? JSON.parse(localStorage.bookmarks).includes(title + "/ " + el.title) : false,
                        }
                    )
                }
            } else {
                loop(el.children, (title ? `${title}/ ` : '') + el.title, icon ? icon : el.icon, data)
            }
        })
    }
    await loop(e)
    return data
}

that.$filter = (data) => {

    for (let i = 0; i < data.length; i++) {
        let remove = true
        for (let role of data[i].role) {
            if (role === 'all') {
                remove = false;
                continue;
            }
            if (that.state.userInfo.privileges.includes(role)) {
                remove = false
                break;
            }
        }
        if (remove) {
            data.splice(i, 1)
            i--
        } else {
            if (data[i].children)
                that.$filter(data[i].children)
        }
    }
}

that.$havePermission = (e) => {
    return that.state.userInfo.privileges.includes(e)
}

that.$logout = () => {
    localStorage.clear('token')
    that.state.token = ''
    that.state.gotten = false
    that.$axios.defaults.headers.common['Authorization'] = that.state.token
    try {
        that.$router.push('/login')
    } catch (e) {
        location.reload()
    }

}

that.$getFinnotech = (token) => {
    const baseUrl = "https://adatether.com/finno/api"
    const backEndUrl = "https://adamanagment.com/api"

    that.state.finnotechNid = new NidVerification(baseUrl, backEndUrl, token)
    that.state.finnotechCard = new CardToIban(baseUrl, backEndUrl, token)
}

that.$test = (e) => {
    console.log('event test : ', e)
}

import './georgianToJalali'
