import axiosFinnotech from 'axios'

class Base {
    baseUrl = 'http://127.0.0.1:8000/api'
    token = '';
    backEndUrl = ''

    constructor(baseUrl, backEndUrl, token) {
        this.baseUrl = baseUrl;
        this.token = token;
        this.backEndUrl = backEndUrl;
    }

    axios() {
        // Set config defaults when creating the instance
        const instance = axiosFinnotech.create({
            baseURL: this.baseUrl
        });

// Alter defaults after instance has been created return
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + this.token

        instance.interceptors.response.use(
            (res) => {
                return res.data;
            },
            async (err) => {
                const res = err.response || err.toJSON()

                console.error('result : ', res);


                if (err.response ? ['UNAUTHORIZED'].includes(res.data.status) : false) {
                    return Promise.reject(err);
                }

                if (err.response ? ['Validation Error'].includes(res.data.message) : false) {
                    return Promise.reject(err);
                }

                if (res.data.message === 'Market Is Inactive') {
                    return Promise.reject(err);
                }

                return Promise.reject(err);
            },);

        return instance
    }

    async saveTokenData(type, data, id = null) {
        console.log('test')
        let arg = {
            type: type,
            items: data
        }

        if (id) {
            arg.user_id = id
        }
        await this.axios().post(
            this.backEndUrl + '/finnotech/token',
            arg
        )
    }

}

export class CardToIban extends Base {

    async handle(card) {
        const token = await this.getToken()
        const response = await this.axios().post(
            '/card-to-iban',
            {
                card,
                token
            }
        )
        return response
    }

    async getToken() {
        const {data} = await this.axios().get(this.backEndUrl + '/finnotech/token')
        let items = data?.items
        if (items) {
            try {
                items = JSON.parse(items)
            } catch (e) {
                items
            }
            const timeIsExpired = ((new Date(data.updated_at)).getTime() + parseInt(items.lifeTime)) < (new Date()).getTime()
            if (timeIsExpired) {
                items = null
            }
        }

        if (!items) {
            items = await this.createToken()
        }

        return items['value']

    }

    async createToken(refreshToken = null) {
        if (refreshToken) {
            let items = await this.axios().post(
                '/create-client-credential-token'
            )
            await this.saveToken(items)
            return items
        }

        let items = await this.axios().post(
            '/create-client-credential-token',
            {
                'refresh_token': refreshToken
            }
        )

        this.saveToken(items)
        return items
    }

    async saveToken(items) {
        await this.axios().post(
            this.backEndUrl + '/finnotech/token',
            {
                items
            }
        )
    }

}

export class NidVerification extends Base {
    trackId = ''

    async requestCode(mobile) {

        const res = await this.axios().post(
            '/otp-token-request',
            {
                mobile,
            }
        )
        this.trackId = res.data.track_id
    }

    async confirmOtp(
        mobile,
        code,
        nationalId
    ) {
        let trackId = this.trackId
        const res = await this.axios().post(
            '/confirm-otp',
            {
                mobile,
                code,
                national_id: nationalId,
                track_id: trackId
            }
        )

        const data = res.data
        await this.saveTokenData('NATIONAL_ID_TOKEN', data)
    }

    async handle(id, name, national_code, birthday) {
        let token = await this.getToken(id)

        console.log('token', token)

        const response = await this.axios().post(
            '/nid-verification',
            {
                name,
                national_code,
                birthday,
                token
            }
        )
        console.log('response', response)

        return response
    }

    async getToken(id) {
        const res = await this.axios().get(this.backEndUrl + '/finnotech/token?type=NATIONAL_ID_TOKEN&user=' + id)
        this.userTokenData = res.data
        let {
            updated_at,
            items
        } = res.data
        console.log('items', items)
        console.log('items', updated_at)

        try {
            items = JSON.parse(items)
        } catch (e) {
            items
        }

        let timeIsExpired = ((new Date(updated_at)).getTime() + parseInt(items.lifeTime)) < (new Date()).getTime()
        if (timeIsExpired) {
            const tokenInfoResponse = await this.createToken(items['refreshToken'])
            await this.saveTokenData('NATIONAL_ID_TOKEN', tokenInfoResponse, id)
            return tokenInfoResponse
        }

        return items['value']
    }

    async createToken(refreshToken) {
        const res = await this.axios().post(
            '/create-new-token',
            {
                refresh_token: refreshToken
            }
        )
        return res?.data
    }

}
